import React from 'react';
import styled from 'styled-components';
import decoIcon1 from '../../../../public/img/main/mainDecoIcon1.svg';
import decoIcon2 from '../../../../public/img/main/mainDecoIcon2.svg';
import decoIcon3 from '../../../../public/img/main/mainDecoIcon3.svg';
import mobileDecoIcon1 from '../../../../public/img/main/mainDecoIcon1_mobile.svg';
import mobileDecoIcon2 from '../../../../public/img/main/mainDecoIcon2_mobile.svg';
import mobileDecoIcon3 from '../../../../public/img/main/mainDecoIcon3_mobile.svg';
import Image from 'next/image';
import Box from 'components/layout/Box';
import Flex from 'components/layout/Flex';
import { FlexDiv } from 'styles/commonStyle';
import { useMediaQuery } from 'react-responsive';

const WhiteBackground = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    height: 710px;
    position: relative;
    margin-top: 56px;
    overflow-x: hidden;
    @media screen and (max-width: 1024px) {
        height: 490px;
        margin-top: 47px;
    }
`;

export const Deco = styled(Image)`
    position: absolute;
    z-index: 0;
`;

const Deco1 = styled(Deco)`
    top: 78px;
    right: 24.4271vw;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const Deco2 = styled(Deco)`
    top: 329px;
    left: 6.6146vw;
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const Deco3 = styled(Deco)`
    top: 403px;
    right: 30.8292vw;
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const MobileDeco1 = styled(Deco)`
    display: none;
    @media screen and (max-width: 1024px) {
        display: block;
        top: 90px;
        right: 35px;
    }
`;

const MobileDeco2 = styled(Deco)`
    display: none;
    @media screen and (max-width: 1024px) {
        display: block;
        top: 123px;
        left: 12px;
    }
`;

const MobileDeco3 = styled(Deco)`
    display: none;
    @media screen and (max-width: 1024px) {
        display: block;
        top: 264px;
        left: 33px;
    }
`;

export const SquareDeco = styled.div`
    width: 72px;
    height: 72px;
    border-radius: 11px;
    background: #eaf3fc;
    position: absolute;
    @media screen and (max-width: 1024px) {
        width: 34px;
        height: 34px;
        border-radius: 8px;
    }
`;

const Deco4 = styled(SquareDeco)`
    top: 546px;
    right: 6.4063vw;
    @media screen and (max-width: 1024px) {
        top: 276px;
        right: 6.4vw;
    }
`;

export const Title = styled.h1`
    margin-top: 98px;
    font-size: 60px;
    font-weight: 800;
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
    z-index: 1;
    position: relative;
    > b {
        font-size: 70px;
    }
    > span {
        color: ${({ theme }) => theme.colors.blue};
        font-weight: inherit;
    }
    @media screen and (max-width: 768px) {
        margin-top: 148px;
        font-size: 28px;
        > b {
            font-size: 30px;
        }
    }
`;

export const Desc = styled.h3`
    margin-top: 15px;
    color: #737373;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: 500;
    @media screen and (max-width: 768px) {
        margin-top: 10px;
        font-size: ${({ theme }) => theme.fontSizes.small};
        font-weight: 400;
    }
`;

export const ApplyButton = styled(Flex)`
    padding: 20px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background: linear-gradient(
        95deg,
        rgba(26, 124, 255, 0.8) 0%,
        rgba(133, 32, 245, 0.8) 93.68%
    );
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.25);
    width: fit-content;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: 700;
    @media screen and (max-width: 768px) {
        padding: 15px 20px;
        font-size: ${({ theme }) => theme.fontSizes.medium};
        font-weight: 600;
    }
`;

const ButtonTagBackground = styled(Flex)`
    transform: rotate(-26deg);
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.blue};
    background: ${({ theme }) => theme.colors.white};
    width: 70px;
    height: 28px;
    position: absolute;
    right: 157.81px;
    bottom: 52px;
    @media screen and (max-width: 768px) {
        border-radius: 5px;
        width: 53px;
        height: 20px;
        right: 93.6px;
        bottom: 35px;
    }
`;

const ButtonTag = styled.div`
    font-size: ${({ theme }) => theme.fontSizes.mediumLarge};
    font-weight: 700;
    background: linear-gradient(91deg, #1a7cff -15.73%, #8520f5 112.7%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const ButtonBox = styled.div`
    position: relative;
    cursor: pointer;
`;

const MainSection1 = () => {
    const onMobile = useMediaQuery({ query: '(max-width: 1024px)' });
    return (
        <WhiteBackground>
            <Deco1 src={decoIcon1} alt=" " />
            <Deco2 src={decoIcon2} alt=" " />
            <Deco3 src={decoIcon3} alt=" " />
            <Deco4 />
            <MobileDeco1 src={mobileDecoIcon1} alt=" " />
            <MobileDeco2 src={mobileDecoIcon2} alt=" " />
            <MobileDeco3 src={mobileDecoIcon3} alt=" " />
            <Title>
                나랑 <b>딱</b> 맞는{onMobile && <br />} <span>기업 매칭</span>
            </Title>
            <Desc>효율적으로 빠르게 원하는 기업에 취업할 수 있어요</Desc>
            <Flex
                justifyContent={'center'}
                marginTop={{ base: '94px', lg: '40px' }}
            >
                <ButtonBox>
                    <a
                        href="https://form.start-ing.kr/match-person"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <ApplyButton>매칭 신청하기</ApplyButton>
                    </a>
                    {/* <ButtonTagBackground>
                        <ButtonTag>BETA</ButtonTag>
                    </ButtonTagBackground> */}
                </ButtonBox>
            </Flex>
        </WhiteBackground>
    );
};

export default MainSection1;

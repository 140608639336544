import styled, { css } from 'styled-components';

const whiteBox = css`
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalInner = styled.div`
    width: 100%;
    max-height: calc(780px - 185px - 115.008px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
    @media screen and (max-width: 1024px) {
        max-height: calc(600px - 139px - 97px);
    }
`;

const BlueButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 96px;
    height: 48px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    border-radius: 15px;
    color: #fff;
    background: #98bfff;
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        border-radius: 10px;
        width: 85px;
        height: 47px;
    }
`;

const InputFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    .inputName {
        color: var(--normalInputNameText);
    }
    &:focus-within {
        .inputName {
            color: var(--focusedColor);
        }
        .searchInputList {
            border: 1px solid red;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 4px;
    }
`;

const InvisibleInput = styled.input`
    width: 0;
    height: 0;
    border: none;
`;

const PCBlock = styled.div`
    width: 100%;
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const TabletBlock = styled.div`
    @media screen and (min-width: 1025px) {
        display: none;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
`;

const MobileBlock = styled.div`
    display: none;
    @media screen and (max-width: 1024px) {
        display: block;
        width: 100%;
    }
`;

const EditInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    padding: 0 1px;
    .inputName {
        color: var(--normalInputNameText);
    }
    .inputButton {
        opacity: 0.3;
    }
    &:focus-within {
        .inputName {
            color: var(--focusedColor);
        }
        .inputButton {
            opacity: 1;
        }
    }
`;

const InputValue = styled.div`
    font-size: 16px;
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
`;

const CircleIconWrapper = styled.div`
    width: 30px;
    height: 30px;
    background: #e7e8ea;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    cursor: pointer;
    > span {
        font-weight: 600;
        font-size: 21px;
    }
    @media screen and (max-width: 1024px) {
        width: 30px;
        height: 30px;
    }
`;

const Placeholder = styled.div`
    color: var(--placeholder);
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
`;

const MobileValue = styled.div`
    font-size: 14px;
`;

const TextLength = styled.div<{ isMax?: boolean }>`
    font-weight: 500;
    font-size: 14px;
    color: #a5a5a5;
    ${({ isMax }) =>
        isMax &&
        css`
            color: ${props => props.theme.colors.red};
        `}

    @media screen and (max-width: 456px) {
        min-width: 74px;
        margin-left: 2px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
`;

const LoadingIconWrapper = styled.span`
    margin-right: 8px;
`;

const SearchInputListBox = styled.ul`
    padding: 25px 13px 25px 25px;
    width: 700px;
    list-style: none;
    background: #ffffff;
    box-shadow: 0px 6.01px 10.003px rgba(0, 0, 0, 0.08);
    border-radius: 10.003px;
    z-index: 2;
    max-height: 270px;
    position: absolute;
    > div {
        max-height: 200px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10.003px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #eeeff1;
            border-radius: 15px;
        }
    }
    @media screen and (max-width: 1100px) {
        width: 620px;
    }

    @keyframes HideList {
        from {
            transform: scaleY(1);
        }
        to {
            transform: scaleY(0);
        }
    }
`;

const SearchInputListItem = styled.li`
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    line-height: 19px;
    color: #606975;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0px;
    }
    &:hover,
    &:focus {
        color: #606975;
        font-weight: bold;
    }
    > span {
        &:hover,
        &:focus {
            color: #606975;
            font-weight: bold;
        }
    }
`;

const EmptyItem = styled.div`
    background-color: rgba(239, 241, 245, 1);
    @keyframes skeleton-gradient {
        0% {
            background-color: rgba(239, 241, 245, 0.5);
        }
        50% {
            background-color: rgba(239, 241, 245, 1);
        }
        100% {
            background-color: rgba(239, 241, 245, 0.5);
        }
    }
    animation: skeleton-gradient 1.5s infinite ease-in-out;
`;

const EmptyText = styled(EmptyItem)<{
    width: number;
    height: number;
    marginBottom: number;
}>`
    height: 12px;
    border-radius: 20px;
    width: 100%;
    ${({ width }) =>
        width &&
        css`
            width: ${width}px;
        `}

    ${({ height }) =>
        height &&
        css`
            height: ${height}px;
        `}
    ${({ marginBottom }) =>
        marginBottom &&
        css`
            margin-bottom: ${marginBottom}px;
        `}
`;

export {
    whiteBox,
    FlexDiv,
    ModalInner,
    BlueButton,
    InputFormWrapper,
    InvisibleInput,
    PCBlock,
    TabletBlock,
    MobileBlock,
    InputValue,
    EditInputContainer,
    CircleIconWrapper,
    Placeholder,
    MobileValue,
    TextLength,
    LoadingIconWrapper,
    SearchInputListBox,
    SearchInputListItem,
    EmptyItem,
    EmptyText,
};

import styled from 'styled-components';

export const SubTitle = styled.h2`
    font-size: 56px;
    font-weight: 800;
    text-align: center;
    @media screen and (max-width: 1024px) {
        font-size: ${({ theme }) => theme.fontSizes.large};
        font-weight: 700;
    }
`;
